import { Module } from 'vuex';

import service from '@/store/services/chatAIConversation-service';
import {ChatAIResponse} from "@/types";

interface ChatAIConversationState {
    question?: string;
    answer?: string;
    messages: [];
    conversErrors: []
}

const state: ChatAIConversationState = {
    messages: [],
    conversErrors: []
}

const mutations = {
    SET_AI_CHAT_CONVERSATION(state: ChatAIConversationState, response: ChatAIResponse) {
        state.messages = response.messages;
    },
    SET_AI_CONVERS_ERRORS(state: ChatAIConversationState, response: any) {
        state.conversErrors = response;
    }
}

const actions = {
    individualChatConversation({ commit, dispatch }: any, params: any) {
        return service.individualAIChatConversation(params)
            .then((response: any) => {
                commit('SET_AI_CHAT_CONVERSATION', response);
            });
    },
    expertChatConversation({ commit, dispatch }: any, params: any) {
        return service.expertAIChatConversation(params)
            .then((response: any) => {
                commit('SET_AI_CHAT_CONVERSATION', response);
                commit('SET_AI_CONVERS_ERRORS', []);
            }).catch((err: any) => {
                const {errors} = err;
                const {error} = err;
                commit('SET_AI_CONVERS_ERRORS', errors ?? error);
            });
    }
}

const getters = {
    chatAIconversation: (state: ChatAIConversationState) => state,
}

const chatAIConversationModule: Module<ChatAIConversationState, any> = {
    state,
    mutations,
    actions,
    getters
}

export default chatAIConversationModule;